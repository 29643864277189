<template lang="pug">
form-input-container(
  :label="label"
  :message="message"
  :message-type="messageType"
)
  base-input(
    type="number"
    v-model="innerValue"
    :disabled="disabled"
    :placeholder="placeholder"
  )
</template>

<script lang="ts">
import type { PropType } from "vue";
import { useVModel } from "@vueuse/core";
import { defineComponent } from "vue";

import BaseInput from "@/components/ui/base/BaseInput.vue";
import FormInputContainer from "@/components/ui/form/container/FormInputContainer.vue";

export default defineComponent({
  name: "LotNumberInput",
  components: {
    BaseInput,
    FormInputContainer,
  },
  props: {
    modelValue: {
      type: [Number, String],
      default: 0,
    },
    label: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
    messageType: {
      type: String as PropType<'error'|'info'>,
      default: 'info',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "Введите номер закупки",
    },
  },
  emits: [
    'blur:modelValue',
    'update:modelValue',
  ],
  setup(props, context) {

    // todo type="number" в инпуте не работает в firefox
    const innerValue = useVModel(props, 'modelValue', context.emit)

    return {
      innerValue,
    };
  }
});
</script>

<style scoped lang="scss">
</style>
